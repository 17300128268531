.home {
  background: url("../../assets/images/landing_bg.jpg") center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 104px);
  padding: 20px 10px;
}
.home__image {
  max-width: 525px;
  width: 100%;
}
.home__hr {
  max-width: 320px;
  width: 100%;
  height: 1px;
  background: #ebebeb;
  margin: 30px 0;
}
.home__title {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
}
.home__btn {
  margin-bottom: 20px;
  color: var(--textColor);
}
.home__text {
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}
